<template>
    <div id="page-radius-service-details" :class="{ 'on-sidebar': on_sidebar }">
        <app-loader v-if="loading.service" fixed-on="desktop"></app-loader>

        <div class="container">
            <div class="row" v-if="!on_sidebar">
                <div class="col-12">
                    <router-link class="btn btn-back-to" :to="{ name: 'radius-services' }">Back to Radius Services</router-link>

                    <div class="header-info">
                        <h1 class="heading">Radius Service Details</h1>
                    </div>
                </div>
            </div>

            <div class="row details">
                <div class="col-mob-12"
                    :class="on_sidebar ? 'col-12' : 'col-6'"
                >
                    <app-table-details
                        :rows="rows.details"
                        :data="service_details"

                        title="Details"
                    />
                </div>
            </div>

            <div class="row details">
                <div class="col-12 col-mob-12">
                    <app-table
                        :class="on_sidebar ? 'table-sessions-sidebar' : 'table-sessions'"

                        :rows="sessions"
                        :cols="rows.sessions"

                        :mobile-mode="on_sidebar"

                        title="Sessions"

                        :loading="loading.sessions"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import appLoader from '@/components/app-loader'
import appTableDetails from '@/components/app-table-details'
import appTable from '@/components/app-table'

import formatDateNano from '@/helpers/format-date-nano'

export default {
    props: {
        uuid: { type: String, default: null },
    },

    components: {
        appLoader,
        appTableDetails,
        appTable,
    },

    data() {
        return {
            service: null,
            sessions_list: [],

            rows: {
                details: [
                    { key: 'uudi',          title: 'UUID'         },
                    { key: 'stack',         title: 'Stack'        },
                    { key: 'customer_name', title: 'Username'     },
                    { key: 'service_name',  title: 'Service Name' },
                    { key: 'service_uuid',  title: 'Service UUID' },
                    { key: 'created',       title: 'Created Date' },
                    { key: 'updated',       title: 'Updated Date' },
                ],

                sessions: [
                    { key: 'date',          title: 'Date/Time'    },
                    { key: 'nas',           title: 'NAS'          },
                    { key: 'ip4',           title: 'IPv4'         },
                    { key: 'ip6',           title: 'IPv6'         },
                ],
            },

            loading: {
                service: false,
                sessions: false,
            },

            on_sidebar: false,
        }
    },

    created() {
        this.init()
    },

    mounted() {
        this.on_sidebar = this.$parent.$el.classList.contains('app-sidebar-details')
    },

    methods: {
        init() {
            this.loadServiceDetails()
        },
        
        loadServiceDetails() {
            this.loading.service = true

            this.$store.dispatch('getRadiusServiceByUUID', { uuid: this.service_uuid }).then(service => {
                this.service = service

                this.getServiceSessions()

                this.loading.service = false
            }).catch(error => {
                console.log('Error getRadiusServiceByUUID', this.service_uuid)
                console.dir(error)

                if (this.on_sidebar) {
                    this.$emit('close-sidebar')
                } else {
                    this.$router.push({ name: 'radius-services', replace: true })
                }
            })
        },

        getServiceSessions() {
            this.loading.sessions = true
            
            this.$store.dispatch('getRadiusServiceSessions', { uuid: this.service_uuid }).then(sessions => {
                this.sessions_list = sessions

                this.loading.sessions = false
            }).catch(error => {
                console.error(error)

                this.sessions_list = []

                this.loading.sessions = false
            })

            // console.groupEnd()
        },
    },

    watch: {
        service_uuid() {
            this.loadServiceDetails()
        },
    },

    computed: {
        service_uuid() {
            return this.uuid || this.$route.params.uuid
        },

        service_details() {
            return this.service ? {
                uudi:          this.service.UUID,
                stack:         this.service.Stack,
                customer_name: this.service.CustomerName,
                service_name:  this.service.ServiceName,
                service_uuid:  this.service.ServiceUUID,
                created:       this.service.CreatedAtNanos ? formatDateNano(this.service.CreatedAtNanos) : '?',
                updated:       this.service.UpdatedAtNanos ? formatDateNano(this.service.UpdatedAtNanos) : '-',
            } : {}
        },

        sessions() {
            return this.sessions_list.map(session => ({
                date: session.ConnectedAtNanos ? formatDateNano(session.ConnectedAtNanos) : '?',
                nas: session.NAS,
                ip4: session.IPv4,
                ip6: session.IPv6,
            }))
        },
    },
}
</script>

<style lang="scss">
#page-radius-service-details {
    margin: 24px 0 80px;

    .btn-back-to {
        margin-bottom: 12px;
    }

    .header-info {
        display: flex;
        justify-content: space-between;
        width: fit-content;
        min-height: 62px;
        margin-bottom: 40px;
    }

    .details {
        margin-bottom: 30px;

        &:last-child { margin: 0 };
    }

    .table-sessions {
        @include table-cols-width((140px, 160px, 110px, 310px), true);
    }
    .table-sessions-sidebar {
        @include table-cols-width-mobile((80px, 395px), true);
    }
}

@media (max-width: $tablet-size) {
    #page-radius-service-details {
        .header-info {
            width: 100%;
            margin-bottom: 48px;
            min-height: 56px;
        }

        .table-sessions {
            @include table-cols-width((120px, 140px, 115px, 260px), true);
        }
        .table-sessions-sidebar {
            @include table-cols-width-mobile((70px, 335px), true);
        }
    }
}

@media (max-width: $mobile-size) {
    #page-radius-service-details {
        margin: 24px 0 64px;

        .btn-back-to {
            font-size: 16px;
            line-height: 18px;
            margin-bottom: 8px;
        }

        .header-info {
            flex-direction: column;
            margin-bottom: 32px;
            min-height: 40px;
        }

        .details {
            margin-bottom: 15px;
        }

        .table-sessions {
            @include table-cols-width-mobile((60px, 215px), true);
        }
        .table-sessions-sidebar {
            @include table-cols-width-mobile((65px, 210px), true);
        }
    }
}
</style>