<template>
    <base-layout>
        <template v-slot:sidebar>
            <app-sidebar-details title="Service details" @close-sidebar="closeSidebar">
                <service-details :uuid="uuid" @close-sidebar="closeSidebar"></service-details>
            </app-sidebar-details>
        </template>

        <div class="page-radius-services container">
            <div class="row">
                <div class="col-12">
                    <h1 class="heading">Radius Services</h1>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <app-table
                        class="radius-services-table"

                        :cols="cols"
                        :rows="services"

                        :clickable="true"
                        :selected-row="uuid"

                        :sort-value="filter.sort"
                        :sort-options="sort"
                        @sort-change="onSortChange"

                        @row-click="showServiceDetails"
                        @row-click-mobile="openServiceDetails"
                    />

                    <app-pagination
                        v-if="pagination.total"

                        v-model="pagination.page"

                        :total="pagination.total"
                        :limit="pagination.limit"
                        :page-range="pagination.range"

                        @change="onPageChange"
                    />
                </div>
            </div>
        </div>

        <app-loader v-if="loading"></app-loader>
    </base-layout>
</template>

<script>
import { mapGetters } from 'vuex'

import baseLayout from '@/views/layouts/base-layout'

import appSidebarDetails from '@/components/app-sidebar-details'
import serviceDetails from '@/views/radius/service-details'

import appLoader from '@/components/app-loader'
import appTable from '@/components/app-table'
import appPagination from '@/components/app-pagination'

import formatDateNano from '@/helpers/format-date-nano'

export default {
    components: {
        baseLayout,

        appSidebarDetails,
        serviceDetails,

        appLoader,
        appTable,
        appPagination,
    },

    data() {
        return {
            services_list: [],

            filter: {
                word: '',
                previous: '',
                sort: {
                    field: 'UpdatedAtNanos',
                    order: 'asc'
                },
            },

            sort: [
                { field: 'Stack',          title: 'Stack'        },
                { field: 'CustomerName',   title: 'User Name'    },
                { field: 'ServiceName',    title: 'Service Name' },
                { field: 'UpdatedAtNanos', title: 'Last Updated' },
            ],
            
            cols: [
                { key: 'stack',         title: 'Stack',        sort: { field: 'Stack' }          },
                { key: 'customer_name', title: 'Username',     sort: { field: 'CustomerName' }   },
                { key: 'service_name',  title: 'Service Name', sort: { field: 'ServiceName' }    },
                { key: 'updated',       title: 'Last Updated', sort: { field: 'UpdatedAtNanos' } },
            ],

            pagination: {
                page: 1,
                total: 0,
                limit: 10,
                range: 2,
            },
            
            uuid: '', // selected row

            loading: false,
        }
    },

    methods: {
        init(to, from) {
            const getRadiusServices = () => { this.getRadiusServices(to.params.page) }

            if (from.name && /^radius-service(-|$)/i.test(from.name)) {
                this.loadRadiusServicesFilter().then(getRadiusServices).catch(getRadiusServices)
            } else {
                this.$store.dispatch('clearRadiusServicesFilter').then(getRadiusServices).catch(getRadiusServices)
            }
        },

        loadRadiusServicesFilter() {
            return this.$store.dispatch('loadRadiusServicesFilter').then(loaded_filter => {
                let filter = {}

                for (const key in this.filter) {
                    filter[key] = key in loaded_filter
                        ? typeof this.filter[key] == 'string'
                            ? loaded_filter[key]
                            : {...this.filter[key], ...loaded_filter[key]}
                        : this.filter[key]
                }
                
                this.filter = filter
            }).catch(error => Promise.resolve(error))
        },

        getRadiusServices(page, filter) {
            // console.group('getRadius(page) {')
            // console.log('page', page)

            this.loading = true

            filter = {
                ...this.filter,
                ...this.search,
                ...filter
            }
            // console.warn('filter', {...filter})

            let params = {
                'IncludeInactive': true,
                'SearchOptions.PageNumber': page ? page : this.pagination.page,
                'SearchOptions.PageSize': this.pagination.limit,
                'SearchOptions.SortBy': filter.sort.field,
                'SearchOptions.SortDesc': filter.sort.order === 'desc' ? 1 : 0,
            }

            const word = filter.word.trim()
            // console.log('word', word)

            if (word.length > 0) {
                params = {
                    ...params,
                    'SmartSearch': word,
                    'SearchOptions.PageNumber': word !== filter.previous ? 1 : params['SearchOptions.PageNumber']
                }

                this.filter.word = word
                this.filter.previous = word
            }
            // console.log('params', {...params})

            this.$store.dispatch('getRadiusServices', { params, filter }).then(response => {
                this.services_list = response.Services

                const { DisplayPageList, PageNumber, TotalItemCount } = response.PageInfo

                this.pagination.page = PageNumber
                this.pagination.total = TotalItemCount || DisplayPageList.length * this.pagination.limit

                if (this.$route.params.page != this.pagination.page
                    && !(
                        this.$route.params.page === undefined
                        && this.pagination.page === 1
                    )
                ) {
                    this.$router.push({ name: this.$route.name, params: { page: this.pagination.page }, replace: true })
                }

                this.loading = false
            }).catch(error => {
                console.error(error)

                this.services_list = []

                this.loading = false
            })

            // console.groupEnd()
        },



        /**
         * Filtering
         */
        onSearch(search) {
            // console.group('onSearch(search) {')
            // console.log('search', {...search})
            // console.log('search.word.trim()', search.word.trim())
            // console.log('this.filter.word.trim()', this.filter.word.trim())

            if (search.word.trim() != this.filter.word.trim()) {
                this.getRadiusServices(this.pagination.page, { word: search.word })
            }

            // console.groupEnd()
        },

        onSortChange(sort) {
            this.filter.sort = sort
            this.getRadiusServices()
        },

        onPageChange(page) {
            this.getRadiusServices(page)
        },



        /**
         * Service Details
         */
        openServiceDetails(member) {
            this.$router.push({ name: 'member', params: { uuid: member.uuid } })
        },

        showServiceDetails(row) {
            if (this.uuid == row.uuid) {
                this.closeSidebar()
            } else {
                this.uuid = row.uuid
                this.openSidebar()
            }
        },

        openSidebar() {
            this.$emit('open-sidebar')
        },

        closeSidebar() {
            this.uuid = null
            this.$emit('close-sidebar')
        },

        openServiceDetails(row) {
            this.$router.push({ name: 'radius-service-details', params: { uuid: row.uuid } })
        },
    },

    computed: {
        services() {
            return this.services_list.map(service => ({
                uuid: service.UUID,
                stack: service.Stack,
                customer_name: service.CustomerName,
                service_name: service.ServiceName,
                updated: service.UpdatedAtNanos ? formatDateNano(service.UpdatedAtNanos) : '?',
            }))
        },
    },
    
    beforeRouteEnter(to, from, next) {
        next(route => { route.init( to, from ) })
    },

    beforeRouteLeave(to, from, next) {
        this.closeSidebar()
        next()
    },
}
</script>

<style lang="scss">
.page-radius-services {
    h1 {
        &.heading {
            margin-bottom: 48px;
        }
    }

    .radius-services-table {
        @include table-cols-width((100px, 150px, 150px, 145px), true);
    }

    .app-pagination {
        margin-top: 30px;
    }
}

@media (max-width: $tablet-size) {
    .page-radius-services {
        .radius-services-table {
            @include table-cols-width((100px, 150px, 150px, 125px), true);
        }
    }
}

@media (max-width: $mobile-size) {
    .page-radius-services {
        h1 {
            &.heading {
                margin-bottom: 32px;
            }
        }

        .radius-services-table {
            margin-bottom: 15px;

            @include table-cols-width-mobile((85px, 190px), true);
        }

        .app-pagination {
            margin-top: 15px;
        }
    }
}
</style>